<template>
  <div class="games">
    <div class="topBanner">
      <img style=" width: 34%;" :src="$game.banner.img" alt="">
      <div class="topBannerContent">
        <h2>{{ $game.banner.title }}</h2>
        <p>{{ $game.banner.p }}</p>
      </div>
    </div>
    <div class="game">
      <div class="gameContent" v-for="item in this.$game.game">
        <div>
          <h2>{{ item.title }}</h2>
          <p>{{ item.p }}</p>
          <!-- <a href="https://play.google.com/store/apps/details?id=com.co.sev.max&hl=en&gl=hk" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/google.png" class="google" alt=""></a> -->
        </div>
        <img class="gameLogo" :src="item.img" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  components: {
  },
  computed: {
    ...Vuex.mapGetters([
      "bannerImg",
      "headerImg",
    ])
  },

}
</script>
<style lang="stylus">
.games {
  background-color: #f3f3f3;
  padding-top: .98rem;
  .topBanner {
    margin: 0 auto;
    padding: 1.04rem 0 ;
  }
  & > div {
    font-family: gilroy;
    &:nth-child(even) {
      background-color: #fff;
    }
    h2 {
      font-weight: bold;
      font-size: .5rem;
      font-family: gotham;
    }
  }
  .topBanner {
    flex( row, nowrap, center, center );
    h2 {
      font-size: .8rem;
    }
    img {
      height: auto;
      margin-right: 5%;
      margin-left: 4%;
    }
    .topBannerContent {
      width: 40%;
      text-align: left;
      margin-left: 5%;
      p {
        width: 34%;
        min-width: 4.2rem;
        margin: .1rem 0;
        line-height: .3rem;
        color: $C0;
        font-size: .18rem;
      }
    }
    
  }
  .game {
    font-size: .18rem;
    .gameContent {
      max-width: 11.4rem;
      flex(row, nowrap, space-between, center);
      margin: 0 auto;
      padding: .5rem .4rem;
      .gameLogo {
        height: 5.75rem;  
      }
      & >div {
        max-width: 4.2rem;
      }
      .google {
        margin-top: .3rem;
        cursor: pointer;
        width: 1.6rem;
      }
        
    }  
    p {
      margin: .2rem auto 0;
      line-height: .3rem;
      color: $C0;
    }
  }
}
</style>